import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Fab } from '@mui/material';
import { Delete } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { HashLink } from 'react-router-hash-link';

export const Structure = () => {
  const { t } = useTranslation();
  return (
    <section id="structure" className="help__section">
      <h2 className="help__header2">{t('titles.structure', { ns: 'help' })}</h2>
      <p className="help__text">{t('structure.p1', { ns: 'help' })}</p>
      <h3 className="help__text">{t('structure.dictModuleTitle', { ns: 'help' })}</h3>
      <p className="help__text">
        <Trans
          i18nKey="structure.dictModuleDesc1"
          ns="help"
          components={{
            trashBtn: <Delete color="error" className="help__del-icon" sx={{ fontSize: 20 }} />,
          }}
        />
      </p>
      <p className="help__text">
        <Trans
          i18nKey="structure.dictModuleDesc2"
          ns="help"
          components={{
            accent: <span className="help__text_accent-green"></span>,
          }}
        />
      </p>
      <h3 className="help__text">{t('structure.addWordsModuleTitle', { ns: 'help' })}</h3>
      <p className="help__text">
        <Trans
          i18nKey="structure.addWordsModuleDesc1"
          ns="help"
          components={{
            fabBtn: (
              <Fab size="small" color="primary" aria-label="add">
                <AddIcon />
                {/* непонятно почему без следующей строки не работает */}
                <span></span>
              </Fab>
            ),
            bold: <span className="help__text_bold"></span>,
          }}
        />
      </p>
      <p className="help__text">
        <Trans
          i18nKey="structure.addWordsModuleDesc2"
          ns="help"
          components={{
            accent: <span className="help__text_accent-green"></span>,
          }}
        />
      </p>
      <h3 className="help__text">{t('structure.learnModuleTitle', { ns: 'help' })}</h3>
      <p className="help__text">
        <Trans
          i18nKey="structure.learnModuleDesc1"
          ns="help"
          components={{
            bold: <span className="help__text_bold"></span>,
            linkMemo: <HashLink className="help__text-link" to="#memorize"></HashLink>,
            linkIntervalSystem: <HashLink className="help__text-link" to="#revise"></HashLink>,
            btn: <span className="help__text_btn"></span>,
          }}
        />
      </p>
      <p className="help__text">
        <Trans
          i18nKey="structure.learnModuleDesc2"
          ns="help"
          components={{
            accent: <span className="help__text_accent-green"></span>,
          }}
        />
      </p>
      <h3 className="help__text">{t('structure.settingModuleTitle', { ns: 'help' })}</h3>

      <p className="help__text">{t('structure.settingModuleDesc1', { ns: 'help' })}</p>
      <ul className="help__text-list">
        <li className="help__text-list-item">{t('structure.settingModuleLi1', { ns: 'help' })}</li>
        <li className="help__text-list-item">{t('structure.settingModuleLi2', { ns: 'help' })}</li>
        <li className="help__text-list-item">{t('structure.settingModuleLi3', { ns: 'help' })}</li>
      </ul>
    </section>
  );
};
