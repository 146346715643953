import React, { useEffect } from 'react';
import './sass/style.scss';
import './i18n';
import { Navigate, Route, Routes } from 'react-router-dom';
import { SignUp } from 'Pages/SignUp/SignUp';
import { Layout } from 'components/Layout';
import { SignIn } from 'Pages/SignIn/SignIn';
import { PrivateAuthRoutes } from 'common/PrivateRoutes/PrivateAuthRoutes';
import { PrivateRoutes } from 'common/PrivateRoutes/PrivateRoutes';
import { Dictionary } from 'Pages/Dictionary/Dictionary';
import { AddWord } from 'Pages/AddWord/AddWord';
import { Learn } from 'Pages/Learn/Learn';
import { Activation } from 'Pages/Activation/Activation';
import { Help } from 'Pages/Help/Help';
import { Import } from 'Pages/Import/Import';
import { getAppVersion, saveAppVersion } from 'Utils/storage';

export const App = () => {
  useEffect(() => {
    const checkVersion = async () => {
      try {
        const response = await fetch('/manifest.json');
        const manifest = await response.json();
        if (!manifest || typeof manifest.version === 'undefined') {
          console.error('Problem with manifest.json');
          return;
        }
        const savedVersion = getAppVersion();

        if (savedVersion !== manifest.version) {
          if ('caches' in window) {
            const cacheNames = await caches.keys();
            await Promise.all(cacheNames.map((name) => caches.delete(name)));
          }

          console.log('New version:', manifest.version);

          saveAppVersion(manifest.version);
          window.location.reload();
        }
      } catch (error) {
        console.error('Checking version error', error);
      }
    };

    checkVersion();
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<Dictionary />} />
          <Route path="/addword" element={<AddWord />} />
          <Route path="/learn" element={<Learn />} />
          <Route path="/import" element={<Import />} />
        </Route>
        <Route element={<PrivateAuthRoutes />}>
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/activation/:token" element={<Activation />} />
        </Route>
        <Route path="/help" element={<Help />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  );
};

export default App;
