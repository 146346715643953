import { useAppDispatch, useAppSelector } from 'hooks/redux';
import React, { useEffect, useMemo, useState } from 'react';
import { deleteWord, fetchUserData, updateWord } from 'store/reducers/DictionaryActionCreators';
import NorthIcon from '@mui/icons-material/North';
import AddIcon from '@mui/icons-material/Add';
import {
  MaterialReactTable,
  MaterialReactTableProps,
  MRT_Row,
  type MRT_ColumnDef,
  MRT_PaginationState,
} from 'material-react-table';
import { WordExt } from 'types/types';
import { Alert, Box, Button, Fab, IconButton, Snackbar, Tooltip } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { TopBlock } from 'components/TopBlock/TopBlock';
import { Delete } from '@mui/icons-material';
import { dictionarySlice } from 'store/reducers/DictionarySlice';
import validator from 'Utils/Validator';
import createUpdatedWordDto from 'Utils/createUpdatedWordDto';
import { useTranslation } from 'react-i18next';
import { TooltipStyled } from 'components/TooltipStyled/TooltipStyled';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Zoom from '@mui/material/Zoom';

type ValidationErrors = {
  word?: string;
  wordContext?: string;
  translate?: string;
  translateContext?: string;
};

export const Dictionary = () => {
  const dispatch = useAppDispatch();
  const isNeedTips = useAppSelector((state) => state.settings.isNeedTips);

  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  });
  const { t } = useTranslation();

  const { words, error, isPending } = useAppSelector((state) => state.dictionary);

  const { closeError } = dictionarySlice.actions;

  useEffect(() => {
    dispatch(fetchUserData());

    // eslint-disable-next-line
  }, []);

  const handleDeleteRow = (row: MRT_Row<WordExt>) => {
    dispatch(deleteWord(words[row.index]));
  };
  const columns = useMemo<MRT_ColumnDef<WordExt>[]>(
    () => [
      {
        accessorKey: 'word',
        header: t('dict.word'),
        muiTableBodyCellEditTextFieldProps: {
          variant: 'standard',
          required: true,
          type: 'string',
          error: !!validationErrors.word,
          helperText: validationErrors.word,
          onChange: () => {
            if (Object.keys(validationErrors).length) setValidationErrors({});
          },
        },
      },
      {
        accessorKey: 'wordContext',
        header: t('dict.context'),
        muiTableBodyCellEditTextFieldProps: {
          variant: 'standard',
          type: 'string',
          error: !!validationErrors.wordContext,
          helperText: validationErrors.wordContext,
          onChange: () => {
            if (Object.keys(validationErrors).length) setValidationErrors({});
          },
        },
        muiTableHeadCellProps: {
          sx: {
            border: '1px solid #0288d1',
            borderRight: '1px solid #0288d1',
            bgcolor: '#e5f5ff',
            color: '#000000de',
            textDecoration: 'underline',
            borderCollapse: 'collapse',
            width: 'auto',
            fontSize: '16px',
            display: { xs: 'none', md: 'table-cell' },
          },
        },
        muiTableBodyCellProps: {
          sx: {
            borderRight: '1px solid #0288d1',
            fontSize: '16px',
            borderBottom: '1px solid #b1dce6',
            display: { xs: 'none', md: 'table-cell' },
          },
        },
      },
      {
        accessorKey: 'translate',
        header: t('dict.translation'),
        muiTableBodyCellEditTextFieldProps: {
          variant: 'standard',
          required: true,
          type: 'string',
          error: !!validationErrors.translate,
          helperText: validationErrors.translate,
          onChange: () => {
            if (Object.keys(validationErrors).length) setValidationErrors({});
          },
        },
        muiTableBodyCellProps: {
          sx: {
            borderRight: { xs: '0', md: '1px solid #0288d1' },
            fontSize: '16px',
            borderBottom: '1px solid #b1dce6',
          },
        },

        muiTableHeadCellProps: {
          sx: {
            border: '1px solid #0288d1',
            borderRight: { xs: '0', md: '1px solid #0288d1' },
            bgcolor: '#e5f5ff',
            color: '#000000de',
            textDecoration: 'underline',
            borderCollapse: 'collapse',
            width: 'auto',
            fontSize: '16px',
          },
        },
      },
      {
        accessorKey: 'translateContext',
        header: t('dict.contextTranslation'),
        muiTableBodyCellEditTextFieldProps: {
          variant: 'standard',
          type: 'string',
          error: !!validationErrors.translateContext,
          helperText: validationErrors.translateContext,
          onChange: () => {
            if (Object.keys(validationErrors).length) setValidationErrors({});
          },
        },
        muiTableHeadCellProps: {
          sx: {
            border: '1px solid #0288d1',
            borderRight: '0',
            bgcolor: '#e5f5ff',
            color: '#000000de',
            textDecoration: 'underline',
            borderCollapse: 'collapse',
            width: 'auto',
            fontSize: '16px',
            display: { xs: 'none', md: 'table-cell' },
          },
        },
        muiTableBodyCellProps: {
          sx: {
            borderRight: '0',
            fontSize: '16px',
            borderBottom: '1px solid #b1dce6',
            display: { xs: 'none', md: 'table-cell' },
          },
        },
      },
      {
        accessorKey: 'nextRepeat',
        header: t('dict.nextRepeat'),

        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          variant: 'standard',
          disabled: true,
          value: new Date(cell.getValue<Date>()).toLocaleTimeString([], {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          }),
        }),
      },
    ],
    [validationErrors, t]
  );
  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleSaveRowEdits: MaterialReactTableProps<WordExt>['onEditingRowSave'] = async ({
    exitEditingMode,
    row,
    values,
  }) => {
    const wordDto = createUpdatedWordDto({ ...values, index: row.index, _id: row.original._id });
    const errors = validator.updatedWord(wordDto);

    setValidationErrors(errors);

    if (!Object.keys(errors).length) {
      values.nextRepeat = row.original.nextRepeat;
      dispatch(updateWord(wordDto));
      setValidationErrors({});
      exitEditingMode();
    }
  };
  const handleCloseSnack = () => {
    dispatch(closeError());
  };

  return (
    <div className="dictionary">
      <TopBlock isViewDisabled={true} />
      {isNeedTips && (
        <div className="dictionary__tip">
          <TooltipStyled
            enterTouchDelay={0}
            leaveTouchDelay={18000}
            TransitionComponent={Zoom}
            placement="bottom-start"
            title={
              <>
                <ol className="tip__tooltip tip__tooltip_list">
                  <li>
                    {t('tips.message.dictionaryHelpTip1')}
                    <br />
                    <Fab size="small" color="primary" aria-label="add">
                      <AddIcon />
                    </Fab>
                  </li>
                  <br />
                  <li>{t('tips.message.dictionaryHelpTip2')}</li>
                  <br />
                  <li>
                    {t('tips.message.dictionaryHelpTip3')}
                    <br />
                    <Button size="small" variant="contained">
                      {t('topBlock.learn')}
                    </Button>
                  </li>
                  <br />
                </ol>
                <span className="tip__tooltip">
                  {t('tips.message.dictionaryHelpTip4')}
                  <br />
                  <br /> {t('tips.message.dictionaryHelpTip5')}
                  <br />
                  <br />
                  <span className="tip__remark">{t('tips.message.turnOffTips')}</span>
                </span>
              </>
            }
            arrow
          >
            <HelpOutlineIcon sx={{ fontSize: 20, mr: 0.5 }} className="menu__tip-icon" />
          </TooltipStyled>
          {t('tips.quickHelp')}
        </div>
      )}
      {!!words.length || isPending ? (
        <div className="dictionary-main">
          <MaterialReactTable
            columns={columns}
            initialState={{
              density: 'compact',
              columnVisibility: { nextRepeat: false },
            }}
            data={words}
            icons={{
              // eslint-disable-next-line
              ArrowDownwardIcon: (props: any) => <NorthIcon {...props} />,
            }}
            onEditingRowSave={handleSaveRowEdits}
            onEditingRowCancel={handleCancelRowEdits}
            enableColumnActions={false}
            enableColumnFilters={false}
            // enablePagination={false}
            // manualPagination
            positionPagination="both"
            onPaginationChange={setPagination}
            // enableBottomToolbar={false}
            muiTablePaginationProps={{
              // labelRowsPerPage: null,
              rowsPerPageOptions: [-1],
              labelDisplayedRows: ({ from, to, count }) => `${from}-${to} of ${count}`,
              sx: {
                '& .MuiTablePagination-toolbar .MuiTablePagination-actions': {
                  ml: '10px',
                },
              },

              //   labelDisplayedRows: ({ from, to, count, page }) =>
              //     `page: ${page + 1} of ${Math.ceil(count / 50)}`,
            }}
            muiBottomToolbarProps={{
              sx: {
                '& .MuiBox-root': {
                  padding: '0',
                },
              },
            }}
            // enableTopToolbar={false}
            enableToolbarInternalActions={false}
            enableEditing
            renderEmptyRowsFallback={() => {
              return (
                <div className="dictionary__empty">
                  <div className="dictionary__empty-text">{t('dict.emptyDictionary')}</div>
                </div>
              );
            }}
            displayColumnDefOptions={{
              'mrt-row-actions': {
                header: '', //change header text

                muiTableBodyCellProps: {
                  sx: {
                    padding: 0,
                    borderLeft: '0',
                    borderRight: '1px solid #0288d1',
                    borderBottom: '1px solid #b1dce6',
                  },
                },
              },
            }}
            renderRowActions={({ row }) => {
              return (
                <Box sx={{ display: 'flex' }}>
                  <Tooltip arrow placement="right" title="Delete">
                    <IconButton
                      color="error"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteRow(row);
                      }}
                    >
                      <Delete sx={{ fontSize: 20 }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              );
            }}
            muiTableBodyRowProps={({ row, table }) => ({
              //add onClick to row to select upon clicking anywhere in the row
              onClick: () => {
                table.setEditingRow(row);
              },
              sx: { cursor: 'pointer' },
            })}
            muiTableHeadCellColumnActionsButtonProps={{
              sx: {
                display: 'none',
              },
            }}
            state={{
              isLoading: isPending,
              showSkeletons: isPending,
              pagination,
            }}
            muiTablePaperProps={{
              sx: {
                boxShadow: 'none',
              },
            }}
            muiTableProps={{
              sx: {
                borderCollapse: 'collapse',
                tableLayout: 'fixed',
                //   border: '1px solid #000000de',
              },
            }}
            muiTableHeadCellProps={{
              sx: {
                color: '#000000de',
                textDecoration: 'underline',
                borderCollapse: 'collapse',
                border: '1px solid #0288d1',
                borderLeft: '0',
                bgcolor: '#e5f5ff',
                width: 'auto',
                fontSize: '16px',
                '&:first-of-type': { maxWidth: '42px', width: '42px' },
                '& svg': { fontSize: '14px' },
              },
            }}
            muiTableBodyCellProps={{
              sx: {
                color: '#000000de',
                borderCollapse: 'collapse',
                borderRight: '1px solid #0288d1',
                borderBottom: '1px solid #b1dce6',
                width: 'auto',
                fontSize: '16px',
              },
            }}
          />
        </div>
      ) : (
        !isPending && <div className="dictionary__empty">{t('dict.emptyDictionary')}</div>
      )}

      <div className="fab-add-word-btn">
        <NavLink to="/addword">
          <Fab color="primary" aria-label="add">
            <AddIcon />
          </Fab>
        </NavLink>
      </div>
      <Snackbar
        open={!!error}
        autoHideDuration={4000}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{ bottom: { xs: 20 } }}
      >
        <Alert severity="error" onClose={handleCloseSnack}>
          {error?.message}
        </Alert>
      </Snackbar>
    </div>
  );
};
