import { IconButton, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import React, { useRef, useState } from 'react';
import { dictionarySlice } from 'store/reducers/DictionarySlice';
import { createDict } from 'store/reducers/DictionaryActionCreators';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';

export const AddNewDict = () => {
  const [newDictName, setDictName] = useState('');
  const [isError, setIsError] = useState(false);
  const saveBtnClickedRef = useRef(false);
  const textFieldRef = useRef<HTMLInputElement>(null);
  const { isDictEditOpen, isDictReqPending } = useAppSelector((state) => state.dictionary);
  const dispatch = useAppDispatch();
  const { toggleDictEditField } = dictionarySlice.actions;

  const changeDictNameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDictName(event.target.value);
  };
  const closeAddingDict = () => {
    if (saveBtnClickedRef.current) {
      saveBtnClickedRef.current = false;
      return;
    }
    dispatch(toggleDictEditField(false));
    setDictName('');
  };

  const validateDictName = () => {
    if (!newDictName.trim()) {
      setIsError(true);
      textFieldRef.current?.focus();
      return false;
    }
    return true;
  };

  const addDictHandler = async (event: React.KeyboardEvent | React.MouseEvent) => {
    if (!isDictEditOpen) {
      dispatch(toggleDictEditField(true));
      return;
    }
    if (
      event.type === 'keyup' &&
      (event as React.KeyboardEvent<HTMLInputElement>).key === 'Escape'
    ) {
      closeAddingDict();
      return;
    }
    if (
      event.type === 'keyup' &&
      (event as React.KeyboardEvent<HTMLInputElement>).key !== 'Enter'
    ) {
      setIsError(false);
      return;
    }
    addDict();
  };

  const addDict = async () => {
    if (!validateDictName()) return;
    await dispatch(createDict(newDictName));
    setDictName('');
    saveBtnClickedRef.current = false;
  };

  const handleSaveBtnClick = () => {
    saveBtnClickedRef.current = true;
  };
  const saveDictButton = (
    <IconButton sx={{ mr: 'auto' }} onMouseDown={handleSaveBtnClick} onClick={addDict}>
      <DownloadDoneIcon className="save-dict-btn" sx={{ fontSize: 28 }} />
    </IconButton>
  );
  return (
    <>
      <TextField
        value={newDictName}
        error={isError}
        size="small"
        label="Enter dictionary name"
        variant="outlined"
        onChange={changeDictNameHandler}
        onKeyUp={addDictHandler}
        onBlur={() => closeAddingDict()}
        disabled={isDictReqPending}
        autoFocus
        inputRef={textFieldRef}
      />
      {saveDictButton}
    </>
  );
};
