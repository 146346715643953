import React from 'react';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';

export const Nav = () => {
  const { t } = useTranslation();
  return (
    <nav className="help__nav">
      <ul className="help__nav-links-block">
        <li>
          <HashLink to="#superQuickStart" className="help__link">
            {t('titles.superQuickStart', { ns: 'help' })}
          </HashLink>{' '}
          🚀
        </li>
        <li>
          <HashLink to="#quickStart" className="help__link">
            {t('titles.quickStart', { ns: 'help' })}
          </HashLink>
        </li>
        <li>
          <HashLink to="#magic" className="help__link help__link_magic">
            {t('titles.magic', { ns: 'help' })}
          </HashLink>
        </li>
      </ul>
      <h2 className="help__header2 help__header2_nav">
        {t('titles.wannaKnowMore', { ns: 'help' })}
      </h2>
      <ul className="help__nav-links-block">
        <li>
          <HashLink to="#structure" className="help__link">
            {t('titles.structure', { ns: 'help' })}
          </HashLink>
        </li>
        <li>
          <HashLink to="#features" className="help__link">
            {t('titles.features', { ns: 'help' })}
          </HashLink>{' '}
          🎉
        </li>
        <li>
          <HashLink to="#memorize" className="help__link">
            {t('titles.phoneticMethod', { ns: 'help' })}
          </HashLink>
        </li>
        <li>
          <HashLink to="#revise" className="help__link">
            {t('titles.intervalMethod', { ns: 'help' })}
          </HashLink>
        </li>
      </ul>
    </nav>
  );
};
