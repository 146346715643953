const token = 'token';
const learningSide = 'learningSide';
const needTips = 'needTips';
const appVersion = 'appVersion';
export const saveToken = (accessToken: string) => localStorage.setItem(token, accessToken);
export const getToken = () => {
  const accessToken = localStorage.getItem(token);
  return accessToken ?? '';
};
export const saveLearningSide = (isBackSide: boolean) =>
  localStorage.setItem(learningSide, isBackSide.toString());
export const getLearningSide = () => localStorage.getItem(learningSide) === 'true';
export const saveNeedTips = (isNedTips: boolean) =>
  localStorage.setItem(needTips, isNedTips.toString());
export const getNeedTips = () => !(localStorage.getItem(needTips) === 'false');
export const saveAppVersion = (version: string) => localStorage.setItem(appVersion, version);
export const getAppVersion = () => localStorage.getItem(appVersion) ?? '';
