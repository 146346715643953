import React, { useCallback } from 'react';
import { ReactComponent as LogoFull } from '../../assets/svg/logoFull.svg';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Nav } from './Nav/Nav';
import { SQuickStart } from './Sections/SQuickStart';
import { QuickStart } from './Sections/QuickStart';
import { Magic } from './Sections/Magic';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Structure } from './Sections/Structure';

export const Help = () => {
  const { t } = useTranslation();
  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <div className="help">
      <header className="help__header">
        <div className="help__logo">
          <NavLink to="/">
            <LogoFull />
          </NavLink>
        </div>
      </header>
      <main className="help__main">
        <h1 className="help__title">
          {t('titles.howItWorks', { ns: 'help' })} <span className="help__quest-sign">?</span>
        </h1>
        <Nav />
        <hr className="help__nav-divider" />
        <div className="help__content-wrapper">
          <Magic />
          <SQuickStart />
          <QuickStart />
          <h2 className="help__header2">{t('titles.wannaKnowMore', { ns: 'help' })}</h2>
          <hr className="help__nav-divider" />
          <Structure />

          <div className="fab-go-top-btn" aria-label="go top" onClick={scrollToTop}>
            <ArrowUpwardIcon />
          </div>
        </div>
      </main>
    </div>
  );
};
