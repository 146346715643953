import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { importWords } from './ImportActionCreators';

interface ImportState {
  isPending: boolean;
  isImportSuccess: boolean;
  success: number | null;
  failed: string | null;
  apiError: string | null;
}

const initialState: ImportState = {
  isPending: false,
  isImportSuccess: false,
  success: null,
  failed: null,
  apiError: null,
};

export const importSlice = createSlice({
  name: 'import',
  initialState,
  reducers: {
    resetImportResult: (state) => {
      state.success = null;
      state.failed = null;
    },
    closeSuccessSnackbar: (state) => {
      state.isImportSuccess = false;
    },
    setInvalidLines: (state, action: PayloadAction<string>) => {
      state.failed = action.payload;
    },
    closeError: (state) => {
      state.apiError = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(importWords.fulfilled, (state, action) => {
        state.success = action.payload.successLines;
        state.isPending = false;
        state.isImportSuccess = true;
      })
      .addCase(importWords.pending, (state) => {
        state.isPending = true;
      })
      .addCase(importWords.rejected, (state, action) => {
        state.apiError = action.payload as string;
        state.isPending = false;
      });
  },
});

export default importSlice.reducer;
