// src/components/ImportWords/ImportWords.tsx
import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { importSlice } from 'store/reducers/ImportSlice';
import { importWords } from 'store/reducers/ImportActionCreators';
import { TopBlock } from 'components/TopBlock/TopBlock';
import { Word } from 'types/types';
import validate from 'Utils/Validator';

export const Import = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string>('');
  useEffect(() => {
    return () => {
      dispatch(importSlice.actions.resetImportResult());
    };
    // eslint-disable-next-line
  }, []);
  const [file, setFile] = useState<File | null>(null);
  const { isPending, apiError, isImportSuccess, success, failed } = useAppSelector(
    (state) => state.import
  );
  const { words, currentDict } = useAppSelector((state) => state.dictionary);
  const { resetImportResult, setInvalidLines, closeSuccessSnackbar, closeError } =
    importSlice.actions;
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleClickOpen = () => {
    setOpen(true);
    dispatch(resetImportResult());
  };

  const handleClose = () => {
    setOpen(false);
    setFile(null);
  };

  const closeErrorHandler = () => {
    setError('');
    dispatch(closeError());
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];

      if (selectedFile.size > 1024 * 1024 * 5) {
        setError(t('importWords.error.tooBig') ?? '');
        return;
      }
      if (selectedFile.type !== 'text/plain' && selectedFile.type !== 'text/csv') {
        setError(t('importWords.error.badFileFormat') ?? '');
        return;
      }

      setFile(selectedFile);
    }
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current?.click();
  };

  const validateAndProcessLine = (line: string): Word | null => {
    const delimiter = '\t';
    const parts = line.split(delimiter);
    const word: Partial<Word> = {};
    [word.word, word.translate, word.wordContext, word.translateContext] = parts;

    word.word = word.word?.trim() ?? '';
    word.translate = word.translate?.trim() ?? '';
    word.wordContext = word.wordContext?.trim() ?? '';
    word.translateContext = word.translateContext?.trim() ?? '';
    const error =
      validate.word(word.word) ||
      validate.word(word.translate) ||
      validate.context(word.wordContext) ||
      validate.context(word.translateContext);
    if (!error) return word as Word;

    return null;
  };

  const handleImport = () => {
    if (!file) return;
    let invalidLines = '';
    const CHUNK_SIZE = 1024 * 256; // 256KB чанки
    let offset = 0;
    let lineCount = 0;
    let partial = '';
    const validWords: Word[] = [];

    const decoder = new TextDecoder('utf-8');

    const processChunk = (chunk: ArrayBuffer) => {
      const decodedChunk = decoder.decode(chunk, { stream: true });

      // const isText = /^[\p{L}\p{N}\p{P}\p{S}\p{Z}\t\r\n]*$/u.test(partial + decodedChunk);

      // if (!isText) {
      //   setError(t('importWords.error.badFileFormat') ?? '');
      //   return false;
      // }
      const lines = (partial + decodedChunk).split('|');
      partial = lines.pop() || ''; // Сохраняем неполную последнюю строку

      for (const line of lines) {
        lineCount++;
        if (lineCount > 3000) {
          return false;
        }

        const validWord = validateAndProcessLine(line.trim());
        if (validWord) validWords.push(validWord);
        else {
          invalidLines = invalidLines ? `${invalidLines}, ${lineCount}` : `${lineCount}`;
        }
      }
      return true;
    };

    const readNextChunk = () => {
      if (offset >= file.size) {
        // Обрабатываем последнюю строку
        if (partial) {
          const validWord = validateAndProcessLine(partial.trim());
          if (validWord) validWords.push(validWord);
        }

        if (validWords.length > 0) {
          if (!currentDict.id) {
            setError(t('importWords.error.noDictionary') ?? '');
            return;
          }

          if (words.length + validWords.length > 9999) {
            validWords.splice(9999 - words.length);
            setError(t('importWords.error.limit') ?? '');
          }

          dispatch(setInvalidLines(invalidLines));
          dispatch(importWords(validWords));

          handleClose();
        } else {
          setError(t('importWords.error.noValidWords') ?? '');
        }
        return;
      }

      const blob = file.slice(offset, offset + CHUNK_SIZE);
      const reader = new FileReader();

      reader.onload = (e) => {
        const chunk = e.target?.result as ArrayBuffer;
        if (!chunk || !processChunk(chunk)) {
          return; // Прерываем чтение если превышен лимит строк
        }
        offset += CHUNK_SIZE;
        readNextChunk();
      };

      reader.readAsArrayBuffer(blob);
    };

    readNextChunk();
  };

  return (
    <div className="import">
      <header>
        <TopBlock isViewDisabled={false} />
      </header>
      <main className="import__main">
        <div className="import__main-container">
          <h1 className="import__title">{t('importWords.title')}</h1>
          <ol className="import__list">
            <li className="import__text-list-item">{t('importWords.help.part1')}</li>
            <li className="import__text-list-item">
              {' '}
              <Trans
                i18nKey="importWords.help.part2"
                components={{
                  bold: <span className="import__text_bold"></span>,
                }}
              />
            </li>
            <li className="import__text-list-item">
              {t('importWords.help.part3')}
              <ul className="import__list">
                <li className="import__text-list-item">
                  <pre className="import__code">
                    {t('importWords.help.part4')}
                    <br />
                    {t('importWords.help.part4-1')}
                    <br />
                    {t('importWords.help.part4')}
                    <br />
                    {t('importWords.help.part4-2')}
                  </pre>
                </li>
                <li className="import__text-list-item">{t('importWords.help.part5')}</li>
                <li className="import__text-list-item">{t('importWords.help.part6')}</li>
                <li className="import__text-list-item">{t('importWords.help.part7')}</li>
              </ul>
            </li>
          </ol>
          {success !== null && failed !== null && (
            <div className="import__result">
              <div className="import__result-title">{t('importWords.resultTitle')}</div>

              <div className="import__result-container">
                <div className="import__result-item">
                  {t('importWords.success.success')}
                  {success}
                </div>

                <div className="import__result-item">
                  {t('importWords.success.failed')}
                  {failed}
                </div>
              </div>
            </div>
          )}
          <div className="import__btn">
            {isPending ? (
              <CircularProgress />
            ) : (
              <Button variant="contained" color="primary" onClick={handleClickOpen}>
                {t('common.import')}
              </Button>
            )}
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle className="import__dialog-title">{t('importWords.title')}</DialogTitle>
              <DialogContent>
                <div className="import__upload">
                  <input
                    type="file"
                    accept=".txt,.csv"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleUploadButtonClick}
                    sx={{ mb: 0 }}
                    fullWidth
                    disabled={isPending}
                  >
                    {t('importWords.selectFile')}
                  </Button>
                  <div className="import__filename">{file?.name}</div>
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary" disabled={isPending}>
                  {t('common.cancel')}
                </Button>
                <Button onClick={handleImport} color="primary" disabled={isPending}>
                  {t('common.import')}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </main>
      <Snackbar
        open={!!error || !!apiError}
        autoHideDuration={4000}
        onClose={closeErrorHandler}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{ bottom: { xs: 20 } }}
      >
        <Alert severity="error" onClose={closeErrorHandler}>
          {error || apiError}
        </Alert>
      </Snackbar>
      <Snackbar
        open={isImportSuccess}
        autoHideDuration={4000}
        onClose={() => dispatch(closeSuccessSnackbar())}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{ bottom: { xs: 20 } }}
      >
        <Alert severity="success" onClose={() => dispatch(closeSuccessSnackbar())}>
          {t('importWords.success.common')}
        </Alert>
      </Snackbar>
    </div>
  );
};
