import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import React from 'react';
import { changeCurrentDict, fetchUserData } from 'store/reducers/DictionaryActionCreators';

export const SelectDict = () => {
  const { dicts, currentDict } = useAppSelector((state) => state.dictionary);
  const dispatch = useAppDispatch();
  const changeDict = async (event: SelectChangeEvent) => {
    await dispatch(changeCurrentDict(event.target.value));
    await dispatch(fetchUserData());
  };
  return (
    <Select
      className="top-block__dict-select"
      size="small"
      value={currentDict.id}
      inputProps={{ 'aria-label': 'Without label' }}
      sx={{
        borderRadius: '8px',
        '& .MuiSelect-select': {
          padding: '5px 14px',
        },
      }}
      onChange={changeDict}
    >
      {dicts.map((dict) => (
        <MenuItem key={dict.id} value={dict.id}>
          {dict.name}
        </MenuItem>
      ))}
    </Select>
  );
};
