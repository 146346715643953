import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from 'http/http';
import { ApiError } from 'http/ApiError';
import { Word } from 'types/types';

export const importWords = createAsyncThunk(
  'import/importWords',
  async (validWords: Word[], thunkAPI) => {
    try {
      const response = await api.post('/import', validWords);
      return response.data;
    } catch (error) {
      if (error instanceof ApiError) return thunkAPI.rejectWithValue(error.message);
      return thunkAPI.rejectWithValue("Can't import words");
    }
  }
);
