import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import { Button, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

export const QuickStart = () => {
  const { t } = useTranslation();
  return (
    <section id="quickStart" className="help__section">
      <h2 className="help__header2">{t('titles.quickStart', { ns: 'help' })}</h2>
      <p className="help__text">
        <span className="help__goal-icon">❓ </span>
        <Trans
          i18nKey="quickStart.p1"
          ns="help"
          components={{
            span: <span className="help__rechi-word"></span>,
          }}
        />
        <br />
        <span className="help__goal-icon">☝️ </span>
        <Trans
          i18nKey="quickStart.p2"
          ns="help"
          components={{
            span: <span className="help__rechi-word"></span>,
          }}
        />
      </p>
      <p className="help__text">
        <span className="help__text_bold">{t('quickStart.p3', { ns: 'help' })}</span>
      </p>
      <ol className="help__text-list">
        <Trans
          i18nKey="quickStart.li1"
          ns="help"
          components={{
            item: <li className="help__text-list-item"></li>,
          }}
        />
        <Trans
          i18nKey="quickStart.li2"
          ns="help"
          components={{
            item: <li className="help__text-list-item"></li>,
            fabBtn: (
              <Fab size="small" color="primary" aria-label="add">
                <AddIcon />
                {/* непонятно почему без следующей строки не работает */}
                <span></span>
              </Fab>
            ),
          }}
        />
        <Trans
          i18nKey="quickStart.li3"
          ns="help"
          components={{
            item: <li className="help__text-list-item"></li>,
            btn: <Button size="small" variant="contained"></Button>,
          }}
        />
        <Trans
          i18nKey="quickStart.li4"
          ns="help"
          components={{
            item: <li className="help__text-list-item"></li>,
            btn: <span className="help__text_btn"></span>,
            linkMemo: <HashLink className="help__text-link" to="#memorize"></HashLink>,
          }}
        />
      </ol>
    </section>
  );
};
