import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEn from './data/langs/en/translation.json';
import translationRu from './data/langs/ru/translation.json';
import helpEn from './data/langs/en/help.json';
import helpRu from './data/langs/ru/help.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,

    resources: {
      en: {
        translation: translationEn,
        help: helpEn,
      },
      ru: {
        translation: translationRu,
        help: helpRu,
      },
    },
    fallbackLng: 'en',
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
      lookupLocalStorage: 'rechiLang',
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
