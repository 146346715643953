import {
  Alert,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
} from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { TopBlock } from 'components/TopBlock/TopBlock';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Word } from 'types/types';
import { createWord } from 'store/reducers/DictionaryActionCreators';
import { dictionarySlice } from 'store/reducers/DictionarySlice';
import { useTranslation } from 'react-i18next';

export const AddWord = () => {
  const { isPending, wordCreated, error } = useAppSelector((state) => state.dictionary);
  const { closeError } = dictionarySlice.actions;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const dividerRef = useRef<HTMLHRElement>(null);
  const firstTextFieldRef = useRef<HTMLInputElement>(null);

  const [showConext1, setShowConext1] = useState(false);
  const [showConext2, setShowConext2] = useState(false);
  const {
    register,
    handleSubmit,
    resetField,
    reset,
    setFocus,
    formState: { errors },
  } = useForm<Word>();
  const handleScroll = useCallback(() => {
    if (dividerRef.current) {
      dividerRef.current.scrollIntoView();
    }
  }, []);

  useEffect(() => {
    setShowConext1(false);
    setShowConext2(false);

    // firstTextFieldRef.current?.focus();
  }, [wordCreated]);

  // useEffect(() => {
  //   handleScroll();
  // }, []);

  const onSubmit = (word: Word) => {
    setFocus('word');
    reset();
    handleScroll();
    dispatch(createWord(word));
  };

  const handleCloseSnack = () => {
    dispatch(closeError());
  };

  return (
    <div className="add-word">
      <TopBlock isViewDisabled={false} />
      <Divider ref={dividerRef} sx={{ mb: '20px' }} />
      <form className="add-word__form" onSubmit={handleSubmit(onSubmit)}>
        <div className="add-word__word-wrapper">
          <TextField
            tabIndex={0}
            type="text"
            label={t('dict.word')}
            fullWidth
            inputRef={firstTextFieldRef}
            variant="outlined"
            {...register('word', {
              required: t('dict.word') as string,
              maxLength: {
                value: 100,
                message: t('dict.CharMax100') as string,
              },
            })}
            error={!!errors.word}
            helperText={errors.word?.message}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton tabIndex={-1} onMouseDown={() => resetField('word')} edge="end">
                    <CancelOutlinedIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputProps={{
              autoCapitalize: 'none',
              autoCorrect: 'off',
              autoFocus: true,
              inputMode: 'search',
            }}
          />
          <Button
            tabIndex={-1}
            variant="text"
            size="small"
            sx={{ color: 'currentColor' }}
            onClick={() => setShowConext1(!showConext1)}
          >
            + {t('dict.addContext')}
          </Button>
          <Collapse tabIndex={-1} in={showConext1} sx={{ width: '100%' }}>
            <textarea
              tabIndex={-1}
              className="add-word__textarea"
              rows={5}
              maxLength={300}
              placeholder={t('dict.addContextPlaceHolder') as string}
              {...register('wordContext', {
                maxLength: {
                  value: 300,
                  message: t('dict.CharMax300') as string,
                },
              })}
            ></textarea>
          </Collapse>

          <TextField
            tabIndex={1}
            fullWidth
            label={t('dict.translation')}
            variant="outlined"
            {...register('translate', {
              required: t('dict.enterWordTranslation') as string,
              maxLength: {
                value: 100,
                message: t('dict.CharMax100') as string,
              },
            })}
            error={!!errors.translate}
            helperText={errors.translate?.message}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton tabIndex={-1} onMouseDown={() => resetField('translate')} edge="end">
                    <CancelOutlinedIcon />
                    {/* {showPassword ? <VisibilityOff /> : <Visibility />} */}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputProps={{ autoCapitalize: 'none', autoCorrect: 'off', inputMode: 'search' }}
          />
          <Button
            tabIndex={-1}
            size="small"
            variant="text"
            sx={{ color: 'currentColor' }}
            onClick={() => setShowConext2(!showConext2)}
          >
            + {t('dict.addContext')}
          </Button>
          <Collapse tabIndex={-1} in={showConext2} sx={{ width: '100%' }}>
            <textarea
              tabIndex={-1}
              className="add-word__textarea"
              rows={5}
              maxLength={300}
              placeholder={t('dict.addContextTranslationPlaceHolder') as string}
              {...register('translateContext', {
                maxLength: {
                  value: 300,
                  message: t('dict.CharMax300') as string,
                },
              })}
            ></textarea>
          </Collapse>
        </div>
        {isPending ? (
          <CircularProgress />
        ) : (
          <Button
            tabIndex={0}
            className="add-word__save-button"
            variant="contained"
            type="submit"
            disabled={isPending}
            size="large"
            sx={{ padding: '6px 40px', fontSize: '16px', mb: '6px' }}
          >
            {t('save')}
          </Button>
        )}
      </form>
      <Snackbar
        open={!!error}
        autoHideDuration={4000}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{ bottom: { xs: 20 } }}
      >
        <Alert severity="error" onClose={handleCloseSnack}>
          {error?.message}
        </Alert>
      </Snackbar>
    </div>
  );
};
