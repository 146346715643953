import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';

export const SQuickStart = () => {
  const { t } = useTranslation();
  return (
    <section id="superQuickStart" className="help__section">
      <h2 className="help__header2">{t('titles.superQuickStart', { ns: 'help' })} 🚀</h2>
      <p className="help__text">
        <Trans
          i18nKey="superQuickStart.p1"
          ns="help"
          components={{
            span: <span className="help__rechi-word"></span>,
          }}
        />
      </p>
      <ol className="help__text-list">
        <Trans
          i18nKey="superQuickStart.p2"
          ns="help"
          components={{
            item: <li className="help__text-list-item"></li>,
          }}
        />
      </ol>

      <p className="help__text">
        <Trans
          i18nKey="superQuickStart.p3"
          ns="help"
          components={{
            icons: (
              <span className="help__text-icon-block">
                <HelpOutlineIcon sx={{ fontSize: 20 }} className="help__text-icon" />
                <LightbulbOutlinedIcon sx={{ fontSize: 20 }} className="help__text-icon" />
                <MenuBookTwoToneIcon className="help__text-icon" sx={{ fontSize: 20 }} />
              </span>
            ),
          }}
        />
      </p>

      <p className="help__text">{t('superQuickStart.p4', { ns: 'help' })}</p>
      <p className="help__text">
        <Trans
          i18nKey="superQuickStart.p5"
          ns="help"
          components={{
            linkSQuickStart: (
              <HashLink className="help__text-link" to="#superQuickStart"></HashLink>
            ),
            linkMemo: <HashLink className="help__text-link" to="#memorize"></HashLink>,
            linkRevise: <HashLink className="help__text-link" to="#revise"></HashLink>,
          }}
        />
      </p>
      <p className="help__text">
        <Trans
          i18nKey="superQuickStart.p6"
          ns="help"
          components={{
            accent: <span className="help__text_accent-green"></span>,
          }}
        />
      </p>
    </section>
  );
};
