import React from 'react';
import { Alert, Button, CircularProgress, Snackbar, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { authSlice } from 'store/reducers/AuthSlice';
import { signUp } from 'store/reducers/AuthActionCreators';
import validator from 'validator';
import { useForm } from 'react-hook-form';
import { UserData } from 'types/types';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LogoFull } from '../../assets/svg/logoFull.svg';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';

export const SignUp = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<UserData>();
  const dispatch = useAppDispatch();
  const { isError, isSuccess, errorMessage, isPending } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();

  const { closeError, closeSuccess } = authSlice.actions;

  const onSubmit = (userData: UserData) => {
    delete userData.rePassword;
    dispatch(signUp(userData));
    reset();
  };
  const handleCloseError = () => {
    dispatch(closeError());
  };
  const handleCloseSuccess = () => {
    dispatch(closeSuccess());
  };

  return (
    <div className="signup">
      <div className="logo">
        <NavLink to="/">
          <LogoFull />
        </NavLink>
      </div>
      <div className="tip">
        <MenuBookTwoToneIcon className="tip__icon" />
        <NavLink to="/help" className="tip__link">
          {t('tips.howItWorks')}
        </NavLink>
      </div>
      <form className="signup-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="signup__wrapper">
          <TextField
            label="E-mail"
            variant="outlined"
            inputProps={{ inputMode: 'email', autoCapitalize: 'none', autoCorrect: 'off' }}
            {...register('email', {
              required: t('sign.emailRequired') as string,
              validate: (value) => validator.isEmail(value) || (t('sign.enterEmail') as string),
            })}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
          <TextField
            label={t('sign.password')}
            variant="outlined"
            inputProps={{ autoCapitalize: 'none', autoCorrect: 'off' }}
            {...register('password', {
              required: t('sign.passwordRequired') as string,
              validate: (value) =>
                validator.isLength(value, { min: 6, max: 100 }) ||
                (t('sign.minCharacters_withValue', { val: '6 - 100' }) as string),
            })}
            error={!!errors.password}
            helperText={errors.password?.message}
          />
          <TextField
            label={t('sign.confirmPassword')}
            variant="outlined"
            inputProps={{ autoCapitalize: 'none', autoCorrect: 'off' }}
            {...register('rePassword', {
              required: t('sign.confirmPasswordRequired') as string,
              validate: (value) =>
                value === watch('password') || (t('sign.passwordMismatch') as string),
            })}
            error={!!errors.rePassword}
            helperText={errors.rePassword?.message}
          />
          <TextField
            label={t('sign.inviteCode')}
            variant="outlined"
            inputProps={{ autoCapitalize: 'none', autoCorrect: 'off' }}
            sx={{ mt: 5 }}
            {...register('invite', {
              required: t('sign.needInvite') as string,
            })}
            error={!!errors.invite}
            helperText={errors.invite?.message}
          />
        </div>
        {isPending ? (
          <CircularProgress />
        ) : (
          <Button
            className="signup__button"
            variant="contained"
            type="submit"
            disabled={isPending}
            fullWidth
          >
            {t('sign.signUp')}
          </Button>
        )}
        <NavLink to="/signin" className="signup__link">
          {t('sign.logIn')}
        </NavLink>

        {/* <a href="/signin" className="signup__link">
          Log in
        </a> */}
      </form>
      <Snackbar
        open={isError}
        autoHideDuration={4000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{ bottom: { xs: 20 } }}
      >
        <Alert severity="error" onClose={handleCloseError}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={isSuccess}
        // autoHideDuration={4000}
        onClose={handleCloseSuccess}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{ bottom: { xs: 20 } }}
      >
        <Alert severity="success" onClose={handleCloseSuccess}>
          {t('sign.registerSuccess')}
          <br />
          {t('sign.checkMailForConfirm')}
        </Alert>
      </Snackbar>
    </div>
  );
};
