import { saveToken } from './storage';
import axios from 'axios';

export const signOut = async () => {
  try {
    await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/auth/logout`, {
      withCredentials: true,
    });
  } catch (error) {}
  saveToken('');
  window.location.replace('/signin');
};
