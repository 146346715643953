import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

export const Magic = () => {
  const { t } = useTranslation();
  return (
    <section id="magic" className="help__section">
      <h2 className="help__header2">{t('titles.magic', { ns: 'help' })}</h2>
      <p className="help__text help__text_italic">{t('magic.p1', { ns: 'help' })}</p>
      <p className="help__text help__text_accent">
        <span className="help__goal-icon">🎯 </span> {t('magic.goal', { ns: 'help' })}
      </p>
      <p className="help__text">{t('magic.p2', { ns: 'help' })}</p>
      <p className="help__text">{t('magic.p22', { ns: 'help' })}</p>
      <p className="help__text">
        <Trans
          i18nKey="magic.p3"
          ns="help"
          components={{
            accent: <span className="help__text_accent"></span>,
          }}
        />
      </p>
      <p className="help__text">
        <Trans
          i18nKey="magic.p33"
          ns="help"
          components={{
            accent: <span className="help__text_accent"></span>,
            btn: <Button size="small" variant="contained"></Button>,
          }}
        />
      </p>
    </section>
  );
};
