import { IconButton, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import React, { useRef, useState } from 'react';
import { dictionarySlice } from 'store/reducers/DictionarySlice';
import { updateDict, deleteDict, fetchUserData } from 'store/reducers/DictionaryActionCreators';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

export const EditDict = () => {
  const [isError, setIsError] = useState(false);
  const btnClickedRef = useRef(false);

  const textFieldRef = useRef<HTMLInputElement>(null);
  const { isDictEditOpen, isDictReqPending, currentDict } = useAppSelector(
    (state) => state.dictionary
  );
  const dispatch = useAppDispatch();
  const { toggleDictEditField } = dictionarySlice.actions;

  const closeEditDict = () => {
    if (btnClickedRef.current) {
      btnClickedRef.current = false;
      return;
    }

    dispatch(toggleDictEditField(false));
  };

  const validateDictName = () => {
    if (!textFieldRef.current?.value.trim()) {
      setIsError(true);
      textFieldRef.current?.focus();
      return false;
    }
    return textFieldRef.current.value.trim();
  };

  const updateDictHandler = async (event: React.KeyboardEvent | React.MouseEvent) => {
    if (!isDictEditOpen) {
      dispatch(toggleDictEditField(true));
      return;
    }
    if (
      event.type === 'keyup' &&
      (event as React.KeyboardEvent<HTMLInputElement>).key === 'Escape'
    ) {
      closeEditDict();
      return;
    }
    if (
      event.type === 'keyup' &&
      (event as React.KeyboardEvent<HTMLInputElement>).key !== 'Enter'
    ) {
      setIsError(false);
      return;
    }
    saveDictChanges();
  };

  const saveDictChanges = async () => {
    const dictNameValidated = validateDictName();
    if (!dictNameValidated) return;
    await dispatch(updateDict({ ...currentDict, name: dictNameValidated }));
    btnClickedRef.current = false;
  };
  const delDict = async () => {
    await dispatch(deleteDict(currentDict));
    await dispatch(fetchUserData());
    btnClickedRef.current = false;
  };

  const handleBtnClick = () => {
    btnClickedRef.current = true;
  };
  const saveDictButton = (
    <IconButton sx={{ mr: 'auto' }} onMouseDown={handleBtnClick} onClick={saveDictChanges}>
      <DownloadDoneIcon className="save-dict-btn" sx={{ fontSize: 28 }} />
    </IconButton>
  );
  const delDictButton = (
    <IconButton>
      <DeleteForeverOutlinedIcon
        color="error"
        className="edit-dict-btn"
        sx={{ fontSize: 28 }}
        onMouseDown={handleBtnClick}
        onClick={delDict}
      />
    </IconButton>
  );
  return (
    <>
      {delDictButton}
      <TextField
        className="top-block__dict-edit-field"
        defaultValue={currentDict.name}
        error={isError}
        size="small"
        label="Change dictionary name"
        variant="outlined"
        onKeyUp={updateDictHandler}
        onBlur={() => closeEditDict()}
        disabled={isDictReqPending}
        autoFocus
        inputRef={textFieldRef}
      />
      {saveDictButton}
    </>
  );
};
