import { Word, WordDto } from 'types/types';

class Validator {
  updatedWord = (word: WordDto) => {
    let error: Record<string, string | undefined> = {};

    error = {
      word: this.word(word.word),
      translate: this.word(word.translate),
      wordContext: this.context(word.wordContext ?? ''),
      translateContext: this.context(word.translateContext ?? ''),
    };
    for (const key in error) {
      if (!error[key]) {
        delete error[key];
      }
    }
    return error;
  };

  word = (word: string): string | undefined => {
    return this.isEmpty(word) || this.isMax(word, 100) || undefined;
  };
  context = (word: string): string | undefined => {
    return this.isMax(word, 300) || undefined;
  };

  private isEmpty = (value: string) => {
    return !value.trim() ? 'The field is required' : false;
  };
  private isMax = (word: string, charCount: number) => {
    return word.length > charCount ? `${charCount} characters max` : false;
  };

  importedFile = (word: Required<Word>): string => {
    const error =
      this.word(word.word) ||
      this.word(word.translate) ||
      this.context(word.wordContext) ||
      this.context(word.translateContext) ||
      '';

    return error;
  };
}

export default new Validator();
